'use strict';

import io from 'socket.io-client';

const opts = {
  parseFlow: () => {},
  hosts: new Map(),
  paused: false,
  sim: false,
  numGen: 250,
  networks: [],
//  websocketUrl: 'http://hrkxodocker1:8080'
//  websocketUrl: 'http://localhost:8080'
//  websocketUrl: '/'
// eslint-disable-next-line no-undef
  websocketUrl: WEBSOCKET_SERVER
};

function ipToNetwork(ip) {
  ip = ip2int(ip);
  return networks.filter(function(net) {
    return ip >= net.subnetLong && ip <= net.broadcastLong;
  })[0];
}

function ip2int(ip) {
  return ip.split('.').reduce(function(ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10);}, 0) >>> 0;
}

function int2ip(ipInt) {
  return ((ipInt >>> 24) + '.' + (ipInt >> 16 & 255) + '.' + (ipInt >> 8 & 255) + '.' + (ipInt & 255));
}

const networks = opts.networks = [
  {
    name: 'PAR Home',
    subnet: '10.65.0.0',
    mask: '255.255.255.0',
    color: [0, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Lenny1',
    subnet: '209.182.235.66',
    mask: '255.255.255.255',
    color: [0, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Lenny4',
    subnet: '209.182.233.68',
    mask: '255.255.255.255',
    color: [0, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Lenny5',
    subnet: '104.225.216.56',
    mask: '255.255.255.255',
    color: [0, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Lenny6',
    subnet: '199.241.139.50',
    mask: '255.255.255.255',
    color: [0, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Docker Bridge',
    subnet: '172.17.0.0',
    mask: '255.255.0.0',
    color: [1, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Docker GW Bridge',
    subnet: '172.18.0.0',
    mask: '255.255.0.0',
    color: [1, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Docker Ingress',
    subnet: '10.255.0.0',
    mask: '255.255.255.0',
    color: [1, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Docker ParInt',
    subnet: '10.0.0.0',
    mask: '255.255.255.0',
    color: [1, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Docker ParWeb',
    subnet: '10.0.1.0',
    mask: '255.255.255.0',
    color: [1, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'XO Public 1',
    subnet: '65.74.154.32',
    mask: '255.255.255.224',
    color: [0.75, 0.5, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'XO Public 2',
    subnet: '65.74.161.0',
    mask: '255.255.255.224',
    color: [0.75, 0.5, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'XO Public 3',
    subnet: '65.74.164.64',
    mask: '255.255.255.224',
    color: [0.75, 0.5, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'HRK Server',
    subnet: '10.10.10.0',
    mask: '255.255.255.0',
    color: [1, 0, 1],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'HRK SIP',
    subnet: '10.10.14.0',
    mask: '255.255.255.0',
    color: [1, 0.2, 1],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'HRK DMZ',
    subnet: '172.16.0.0',
    mask: '255.255.255.0',
    color: [1, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'MCCX Server',
    subnet: '10.10.15.0',
    mask: '255.255.255',
    color: [0, 1, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'MCCX Data',
    subnet: '10.10.12.0',
    mask: '255.255.254.0',
    color: [0, 0.75, 0],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'SFO Server',
    subnet: '10.10.36.0',
    mask: '255.255.255.0',
    color: [0.2, 0.2, 1],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  },
  {
    name: 'Multicast',
    subnet: '224.0.0.0',
    mask: '255.255.255.0',
    color: [0.1, 0.1, 0.5],
    subnetLong: 0,
    broadcastLong: 0,
    maskLong: 0
  }
];

networks.push({
  name: 'WORLD',
  subnet: '0.0.0.0',
  mask: '0.0.0.0',
  color: [1, 0, 0],
  subnetLong: 0,
  broadcastLong: 0,
  maskLong: 0
});

networks.forEach(function(net) {
  net.subnetLong = ip2int(net.subnet);
  net.maskLong = ip2int(net.mask);
  if (net.maskLong) {
    net.broadcastLong = net.subnetLong + (~net.maskLong);
  } else {
    net.broadcastLong = net.subnetLong + 4294967295;
  }
  net.broadcast = int2ip(net.broadcastLong);
});
// console.log('networks', networks);

const protoColors = {
  'other': {
    name: 'other',
    color: [1, 1, 1]
  },
  '6': {
    name: 'tcp',
    color: [1, 0, 0]
  },
  '17': {
    name: 'udp',
    color: [0, 0, 1]
  },
  '1': {
    name: 'icmp',
    color: [0, 1, 0]
  }
};

let socket;
setTimeout(function() {
  if (opts.sim) {
    return;
  }
  socket = io(opts.websocketUrl);

  socket.on('connect', function() {
    console.log('connected');
    // socket.emit('ip_lookup','10.10.12.251');
  });
  socket.on('ip_info', function(ip, hostname) {
    // console.log('ip_info',ip,hostname);
    let host = opts.hosts.get(ip);
    if (host) {
      host.hostname = hostname;
    }
  });
  socket.on('disconnect', function() {
    console.warn('disconnected');
  });

  socket.on('flow', function(packet) {
    if (opts.paused) {
      return;
    }
    // console.log(JSON.stringify(flow));
    packet.flows.forEach(function(flow) {
      // console.log('flow', flow);
      opts.parseFlow(flow);
    });
  });

}, 10);

function randomIp() {
  return (Math.floor(Math.random() * 254) + 1) + '.' +
    (Math.floor(Math.random() * 255)) + '.' +
    (Math.floor(Math.random() * 255)) + '.' +
    (Math.floor(Math.random() * 254) + 1);
}

function genFlow(num) {
  num = num || 1;
  let i, link, flow, protocols = [1, 6, 17];
  let packet = {
    header: {version: 5, count: num},
    flows: []
  };
  let ips = Array.from(opts.hosts.keys());
  let links = Array.from(opts.links.values());
  for (i = 0; i < num; i++) {
    flow = {
      in_pkts: Math.floor(Math.random() * 50) + 1,
      ipv4_src_port: Math.floor(Math.random() * 65535) + 1,
      ipv4_dst_port: Math.floor(Math.random() * 65535) + 1,
      protocol: protocols[Math.floor(Math.random() * protocols.length)]
    };
    if (opts.numGen < -ips.length * 0.3) {
      link = links[Math.floor(Math.random() * links.length)];
      flow.ipv4_src_addr = link.src.ip;
      flow.ipv4_dst_addr = link.dst.ip;
      if (Math.random() >= 0.9) {
        break;
      }
    } else {
      flow.ipv4_src_addr = opts.numGen > 0 ? randomIp() : ips[Math.floor(Math.random() * ips.length)];
      flow.ipv4_dst_addr = opts.numGen > 0 ? randomIp() : ips[Math.floor(Math.random() * ips.length)];
    }
    if (!flow.ipv4_src_addr || !flow.ipv4_dst_addr) {
      continue;
    }
    packet.flows.push(flow);
    opts.numGen--;
  }
  packet.flows.forEach(opts.parseFlow);
}

function requestDns(ip) {
  socket.emit('ip_lookup', ip);
}

function setOpts(newOpts) {
  for (let i in newOpts) {
    if (newOpts.hasOwnProperty(i)) {
      opts[i] = newOpts[i];
    }
  }
  console.log('networkOpts', opts);
}

setInterval(function() {
  if (opts.sim) {
    genFlow(opts.sim);
  }
}, 1000);
// setTimeout(function() {
//   let packet = {
//     'header': {
//       'version': 5,
//       'count': 29,
//       'uptime': 1048580995,
//       'seconds': 1501683609,
//       'nseconds': 69000,
//       'sequence': 5084152,
//       'engine_type': 0,
//       'engine_id': 0,
//       'sampling_interval': 0
//     },
//     'flows': [{
//       'ipv4_src_addr': '10.10.12.155',
//       'ipv4_dst_addr': '184.72.32.75',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 9,
//       'in_bytes': 1542,
//       'first_switched': 1048510165,
//       'last_switched': 1048511089,
//       'ipv4_src_port': 57038,
//       'ipv4_dst_port': 80,
//       'tcp_flags': 26,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.99',
//       'ipv4_dst_addr': '104.254.150.21',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 14,
//       'in_bytes': 7503,
//       'first_switched': 1048501850,
//       'last_switched': 1048512001,
//       'ipv4_src_port': 58061,
//       'ipv4_dst_port': 443,
//       'tcp_flags': 27,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.183',
//       'ipv4_dst_addr': '23.203.59.249',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 2,
//       'in_bytes': 80,
//       'first_switched': 1048511180,
//       'last_switched': 1048511180,
//       'ipv4_src_port': 50253,
//       'ipv4_dst_port': 443,
//       'tcp_flags': 17,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.36.121',
//       'ipv4_dst_addr': '10.10.15.203',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 56,
//       'in_bytes': 9769,
//       'first_switched': 1048485813,
//       'last_switched': 1048515395,
//       'ipv4_src_port': 445,
//       'ipv4_dst_port': 53960,
//       'tcp_flags': 26,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.183',
//       'ipv4_dst_addr': '54.244.94.212',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 18,
//       'in_bytes': 3944,
//       'first_switched': 1048442543,
//       'last_switched': 1048511322,
//       'ipv4_src_port': 50460,
//       'ipv4_dst_port': 443,
//       'tcp_flags': 27,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '74.125.199.109',
//       'ipv4_dst_addr': '10.10.12.137',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 12,
//       'in_bytes': 792,
//       'first_switched': 1048492127,
//       'last_switched': 1048515050,
//       'ipv4_src_port': 993,
//       'ipv4_dst_port': 56013,
//       'tcp_flags': 25,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '54.209.111.199',
//       'ipv4_dst_addr': '10.10.12.183',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 9,
//       'in_bytes': 834,
//       'first_switched': 1048446049,
//       'last_switched': 1048513006,
//       'ipv4_src_port': 80,
//       'ipv4_dst_port': 50697,
//       'tcp_flags': 27,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.14.66',
//       'ipv4_dst_addr': '10.10.10.94',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 22,
//       'in_bytes': 1012,
//       'first_switched': 1048270629,
//       'last_switched': 1048571246,
//       'ipv4_src_port': 49969,
//       'ipv4_dst_port': 2000,
//       'tcp_flags': 24,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '104.254.150.21',
//       'ipv4_dst_addr': '10.10.12.99',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 14,
//       'in_bytes': 5385,
//       'first_switched': 1048501867,
//       'last_switched': 1048512018,
//       'ipv4_src_port': 443,
//       'ipv4_dst_port': 58061,
//       'tcp_flags': 27,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.15.150',
//       'ipv4_dst_addr': '208.67.222.222',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 416,
//       'first_switched': 1048515687,
//       'last_switched': 1048515687,
//       'ipv4_src_port': 26285,
//       'ipv4_dst_port': 53,
//       'tcp_flags': 0,
//       'protocol': 17,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '65.74.164.80',
//       'ipv4_dst_addr': '10.10.12.155',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 9,
//       'in_bytes': 6504,
//       'first_switched': 1048512852,
//       'last_switched': 1048512958,
//       'ipv4_src_port': 443,
//       'ipv4_dst_port': 57048,
//       'tcp_flags': 26,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.15.151',
//       'ipv4_dst_addr': '208.67.220.220',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 84,
//       'first_switched': 1048514136,
//       'last_switched': 1048514136,
//       'ipv4_src_port': 29618,
//       'ipv4_dst_port': 53,
//       'tcp_flags': 0,
//       'protocol': 17,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.183',
//       'ipv4_dst_addr': '54.244.94.212',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 10,
//       'in_bytes': 1687,
//       'first_switched': 1048445899,
//       'last_switched': 1048511321,
//       'ipv4_src_port': 50685,
//       'ipv4_dst_port': 443,
//       'tcp_flags': 27,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.14.153',
//       'ipv4_dst_addr': '10.10.10.94',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 24,
//       'in_bytes': 1104,
//       'first_switched': 1048244213,
//       'last_switched': 1048574902,
//       'ipv4_src_port': 53120,
//       'ipv4_dst_port': 2000,
//       'tcp_flags': 24,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.15.100',
//       'ipv4_dst_addr': '208.67.222.222',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 56,
//       'first_switched': 1048515914,
//       'last_switched': 1048515914,
//       'ipv4_src_port': 56424,
//       'ipv4_dst_port': 53,
//       'tcp_flags': 0,
//       'protocol': 17,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.10.93',
//       'ipv4_dst_addr': '10.10.14.63',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 2,
//       'in_bytes': 80,
//       'first_switched': 1048451041,
//       'last_switched': 1048513694,
//       'ipv4_src_port': 2000,
//       'ipv4_dst_port': 53023,
//       'tcp_flags': 16,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.15.151',
//       'ipv4_dst_addr': '67.215.92.28',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 5,
//       'in_bytes': 300,
//       'first_switched': 1048499336,
//       'last_switched': 1048514365,
//       'ipv4_src_port': 14835,
//       'ipv4_dst_port': 2222,
//       'tcp_flags': 2,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '208.67.220.220',
//       'ipv4_dst_addr': '10.10.15.150',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 332,
//       'first_switched': 1048515195,
//       'last_switched': 1048515195,
//       'ipv4_src_port': 53,
//       'ipv4_dst_port': 36483,
//       'tcp_flags': 0,
//       'protocol': 17,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.10.96',
//       'ipv4_dst_addr': '10.10.15.100',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 113,
//       'first_switched': 1048514822,
//       'last_switched': 1048514822,
//       'ipv4_src_port': 3,
//       'ipv4_dst_port': 3,
//       'tcp_flags': 0,
//       'protocol': 1,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.15.100',
//       'ipv4_dst_addr': '10.10.10.139',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 147,
//       'first_switched': 1048514981,
//       'last_switched': 1048514981,
//       'ipv4_src_port': 52637,
//       'ipv4_dst_port': 53,
//       'tcp_flags': 0,
//       'protocol': 17,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.13.95',
//       'ipv4_dst_addr': '72.21.91.29',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 6,
//       'in_bytes': 501,
//       'first_switched': 1048452717,
//       'last_switched': 1048512748,
//       'ipv4_src_port': 54280,
//       'ipv4_dst_port': 80,
//       'tcp_flags': 30,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.183',
//       'ipv4_dst_addr': '54.209.111.199',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 13,
//       'in_bytes': 4649,
//       'first_switched': 1048445970,
//       'last_switched': 1048512931,
//       'ipv4_src_port': 50697,
//       'ipv4_dst_port': 80,
//       'tcp_flags': 27,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.68',
//       'ipv4_dst_addr': '65.74.154.48',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 293,
//       'in_bytes': 12535,
//       'first_switched': 1048248568,
//       'last_switched': 1048573913,
//       'ipv4_src_port': 50170,
//       'ipv4_dst_port': 443,
//       'tcp_flags': 24,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.183',
//       'ipv4_dst_addr': '31.13.77.12',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 3,
//       'in_bytes': 120,
//       'first_switched': 1048511285,
//       'last_switched': 1048511285,
//       'ipv4_src_port': 50715,
//       'ipv4_dst_port': 443,
//       'tcp_flags': 17,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.15.150',
//       'ipv4_dst_addr': '208.67.220.220',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 288,
//       'first_switched': 1048515188,
//       'last_switched': 1048515188,
//       'ipv4_src_port': 36483,
//       'ipv4_dst_port': 53,
//       'tcp_flags': 0,
//       'protocol': 17,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '12.34.73.53',
//       'ipv4_dst_addr': '10.10.12.168',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 1,
//       'in_bytes': 40,
//       'first_switched': 1048515189,
//       'last_switched': 1048515189,
//       'ipv4_src_port': 443,
//       'ipv4_dst_port': 59525,
//       'tcp_flags': 20,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.12.128',
//       'ipv4_dst_addr': '10.10.10.126',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 8,
//       'in_bytes': 3240,
//       'first_switched': 1048251695,
//       'last_switched': 1048573697,
//       'ipv4_src_port': 49198,
//       'ipv4_dst_port': 80,
//       'tcp_flags': 24,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '74.125.28.189',
//       'ipv4_dst_addr': '10.10.12.71',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 55,
//       'in_bytes': 4512,
//       'first_switched': 1048250769,
//       'last_switched': 1048571808,
//       'ipv4_src_port': 443,
//       'ipv4_dst_port': 57205,
//       'tcp_flags': 24,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }, {
//       'ipv4_src_addr': '10.10.15.203',
//       'ipv4_dst_addr': '10.10.36.121',
//       'ipv4_next_hop': '0.0.0.0',
//       'input_snmp': 0,
//       'output_snmp': 0,
//       'in_pkts': 44,
//       'in_bytes': 23864,
//       'first_switched': 1048487134,
//       'last_switched': 1048515896,
//       'ipv4_src_port': 49772,
//       'ipv4_dst_port': 49156,
//       'tcp_flags': 24,
//       'protocol': 6,
//       'src_tos': 0,
//       'in_as': 0,
//       'out_as': 0,
//       'src_mask': 0,
//       'dst_mask': 0
//     }],
//     'rinfo': {'address': '10.10.15.120', 'family': 'IPv4', 'port': 58650, 'size': 1416},
//     'decodeMs': 0
//   };
//   packet.flows.forEach(function(flow) {
//     opts.parseFlow(flow);
//   });
// }, 1000);

// "C:\Program Files\nProbe\nprobe.exe" /c -i 2 -f ip -V 5 -l 5 -t 5 -b1 -n udp://127.0.0.1:5555

export { protoColors, networks, ip2int, int2ip, ipToNetwork, randomIp, genFlow, requestDns, setOpts };
