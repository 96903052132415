// @flow
'use strict';

// used to time things
function getPerfTime(): number {
  return performance.now ? performance.now() : Date.now();
}

// used to make link id from 2 host id's
function mkLinkId(src: string, dst: string): string | null {
  if (!src || !dst || src === dst) {
    return null;
  }
  return (src < dst) ? src + '-' + dst : dst + '-' + src;
}

type TimerEntryType = { name: string, last: number, duration: number | null };

class Timer {
  timers: Map<string, TimerEntryType>;

  constructor() {
    this.timers = new Map();
  }

  start(name: string): number {
    let timer = this.timers.get(name);
    if (!timer) {
      timer = {
        name,
        last: getPerfTime(),
        duration: null
      };
      this.timers.set(name, timer);
    } else {
      timer.last = getPerfTime();
      timer.duration = null;
    }
    return timer.last;
  }

  has(name: string): boolean {
    return this.timers.has(name);
  }

  duration(name: string, decimalPlaces: number): number {
    let timer = this.timers.get(name);
    if (!timer) {
      return 0;
    }
    if (decimalPlaces !== undefined) {
      return timer.duration = parseFloat((getPerfTime() - timer.last).toFixed(decimalPlaces));
    }
    return timer.duration = getPerfTime() - timer.last;
  }

  remove(name: string) {
    this.timers.delete(name);
  }

  clear() {
    this.timers.clear();
  }

  dump(decimalPlaces: number = 2): Array<string> {
    const st = [];
    this.timers.forEach((v: TimerEntryType, k: string) => {
      st.push(k + '=' + (v.duration === null ? this.duration(k, decimalPlaces) : v.duration));
    });
    return st;
  }
}

export { getPerfTime, mkLinkId, Timer };
