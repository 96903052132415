'use strict';

require('../css/normalize.css');
require('bootstrap/dist/css/bootstrap.css');
require('bootstrap/dist/css/bootstrap-theme.css');
require('bootstrap3-dialog/dist/css/bootstrap-dialog.min.css');
require('../css/main.css');

import $ from 'jquery';
import 'bootstrap';
import BootstrapDialog from 'bootstrap3-dialog';

import {
  Vector3,
  Box3,
  Clock,
  PerspectiveCamera,
  Fog,
  Raycaster,
  Scene,
  Object3D,
  Points,
  BufferGeometry,
  BufferAttribute,
  LineBasicMaterial,
  ShaderMaterial,
  LineSegments,
  TextureLoader,
  WebGLRenderer,
  NormalBlending,
  VertexColors,
  DynamicDrawUsage
} from 'three';

import TrackballControls from './TrackballControls';
import { mkLinkId, Timer } from './utils';

import Detector from './detector';
import Stats from './stats';

import Octree from './octree';
import OctreeVisHelper from './OctreeVizHelper';

import { protoColors, ipToNetwork, requestDns, setOpts as setNetworkOpts } from './network';

import SimWorker from './force-sim-oct.js';
// import SimWorker from './force-sim-worker.js';

const timer = new Timer();

const options = {
  sim: false,
  numGen: 500,
  dns: true,
  localOnly: false,
  autoRotate: true,
  paused: false,
  flatten: false,
  showOctree: false,
  octreeMaxLevel: 4,
  octreeSplitThreshold: 1,
  octreeTheta: 1.0,
  dialogOpen: false
};

if (options.sim) {
  options.localOnly = false; // don't allow local only
  options.dns = false; // don't do dns lookup
}

const simWorker = new SimWorker();
simWorker.addEventListener('error', (err) => {
  console.error(err);
}, false);

simWorker.onmessage = (e) => {
  const data = JSON.parse(e.data);
  if (data.type === 'console') {
    return console[data.level]('simWorker', data.msg);
  }
  if (data.type === 'stats') {
    parStats.simStats = data.data;
    return;
    // return console.log('simWorker', data.data);
  }

  if (data.type === 'nodes') {
    return updateNodesFromSim(data.data);
  }
  if (data.type === 'node') {
    return updateNodesFromSim(data.data);
  }
  if (data.type === 'fps') {
    return simStats.update();
  }
};

simRpc('setOpts', {
  octreeMaxLevel: options.octreeMaxLevel,
  octreeTheta: options.octreeTheta,
  octreeSplitThreshold: options.octreeSplitThreshold,
  flatten: options.flatten
});

function simRpc() {
  let args = [...arguments];
  simWorker.postMessage(JSON.stringify({type: 'rpc', funcName: args.shift(), args}));
}

function findHost(ip, name) {
  console.log('findHost', ip, name);
  if (!ip && !name) {
    return;
  }
  let n;
  if (ip) {
    n = hosts.get(ip);
  } else if (name) {
    hosts.forEach((host) => {
      if (host.hostname.includes(name)) {
        n = host;
        return false;
      }
    });
  }
  if (!n) {
    console.log('host not found for search', ip, name);
    BootstrapDialog.alert('Host not found');
  } else {
    console.log('found host for search', ip, name);
  }
  selectHost(n);
}

function purgeExternalHosts() {
  console.log('purgeExternalHosts');
  hosts.forEach((host) => {
    if (host.network.name === 'WORLD') {
      host.lastSeen = 0;
      host.links.forEach((link) => {
        link.lastSeen = 0;
      });
    }
  });
  timeoutObjects();
}

// simRpc('addHost',1,2,3);

$('#full_screen').click(() => {
  fullScreen(true);
});

function fullScreen(full) {
  if (full) {
    let i = container;
    if (i.requestFullscreen) {
      i.requestFullscreen();
    } else if (i.webkitRequestFullscreen) {
      i.webkitRequestFullscreen();
    } else if (i.mozRequestFullScreen) {
      i.mozRequestFullScreen();
    } else if (i.msRequestFullscreen) {
      i.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

let mouse = {x: 0, y: 0};
let keysDown = {ctrl: false, alt: false, shift: false};
$(document).keydown((e) => {
  if (options.dialogOpen) {
    return;
  }
  keysDown.ctrl = e.ctrlKey;
  keysDown.alt = e.altKey;
  keysDown.shift = e.shiftKey;
  simRpc('setPaused', keysDown.ctrl);
  if (e.shiftKey) {
    if (e.key === '+') {
      options.octreeTheta += 0.1;
      simRpc('setOpts', {octreeTheta: options.octreeTheta});
    }
    if (e.key === '-') {
      if (options.octreeTheta > 0) {
        options.octreeTheta -= 0.1;
        if (options.octreeTheta < 0) {
          options.octreeTheta = 0;
        }
        simRpc('setOpts', {octreeTheta: options.octreeTheta});
      }
    }
  } else if (e.altKey) {
    if (e.key === '+') {
      options.octreeSplitThreshold++;
      simRpc('setOpts', {octreeSplitThreshold: options.octreeSplitThreshold});
    }
    if (e.key === '-') {
      if (options.octreeSplitThreshold > 0) {
        options.octreeSplitThreshold--;
        simRpc('setOpts', {octreeSplitThreshold: options.octreeSplitThreshold});
      }
    }
  } else {
    if (e.key === '+') {
      options.octreeMaxLevel++;
      simRpc('setOpts', {octreeMaxLevel: options.octreeMaxLevel});
    }
    if (e.key === '-') {
      if (options.octreeMaxLevel > 0) {
        options.octreeMaxLevel--;
        simRpc('setOpts', {octreeMaxLevel: options.octreeMaxLevel});
      }
    }
  }
});

$(document).keyup((e) => {
  if (options.dialogOpen) {
    return;
  }
  keysDown.ctrl = e.ctrlKey;
  keysDown.alt = e.altKey;
  keysDown.shift = e.shiftKey;
  simRpc('setPaused', keysDown.ctrl);
  if (e.key === '?' || e.key === 'h' || e.key === 'H') {
    $('#help').toggle();
    return;
  }
  if (e.key === 'r' || e.key === 'R') {
    options.autoRotate = !options.autoRotate;
    return;
  }
  if (e.key === 'o' || e.key === 'O') {
    options.showOctree = !options.showOctree;
    return;
  }
  if (e.key === 'f' || e.key === 'F') {
    options.flatten = !options.flatten;
    simRpc('setOpts', {flatten: options.flatten, temperature: 1.0});
    simRpc('randomizeVelocity');
    return;
  }
  if (e.key === 'j' || e.key === 'J') {
    simRpc('randomizeVelocity');
    return;
  }
  if (e.key === 'v' || e.key === 'V') {
    controls.reset();
    return;
  }
  if (e.key === 't' || e.key === 'T') {
    simRpc('setOpts', {temperature: 1.0});
    return;
  }
  if (e.key === 'l' || e.key === 'L') {
    if (options.sim) {
      return;
    }
    options.localOnly = !options.localOnly;
    if (options.localOnly) {
      purgeExternalHosts();
    }
    return;
  }
  if (e.key === 's' || e.key === 'S') {
    BootstrapDialog.show({
      title: 'Find Host',
      message: '<input class="form-control search-ip" value="" placeholder="ip">' + '<input class="form-control search-name" value="" placeholder="name">',
      onshown: () => {
        $('input.form-control.search-ip').focus();
      },
      onshow: () => {
        options.dialogOpen = true;
        controls.enabled = false;
      },
      onhide: () => {
        options.dialogOpen = false;
        controls.enabled = true;
      },
      buttons: [{
        label: 'Find',
        hotkey: 13,
        action: function(dialogRef) {
          let ip = dialogRef.getModalBody().find('input.search-ip').val().trim();
          let name = dialogRef.getModalBody().find('input.search-name').val().trim();
          findHost(ip, name);

          dialogRef.close();
        }
      }]
    });
  }
});

const toolTip = $('#toolTip');
const hud = $('#hud');

function updateStats() {
  let info = [], st = [];
  info.push(
    'Nodes:' + hosts.size +
    ' Links:' + links.size +
    ' SimNodes:' + parStats.simStats.nodeCount +
    ' SimLinks:' + parStats.simStats.linkCount +
    ' forceUpdateTime ' + parStats.simStats.forceUpdateTime
  );
  if (parStats.simStats.octreeNodes) {
    info.push(
      'Octree nodes:' + parStats.simStats.octreeNodes +
      ' maxLevel:' + parStats.simStats.octreeMaxLevel +
      ' theta:' + parStats.simStats.octreeTheta.toFixed(1) +
      ' splitThresh:' + parStats.simStats.octreeSplitThreshold +
      ' buildTime:' + parStats.simStats.octreeBuildTime
    );
  }
  let i, buff;
  for (i in bufferTrack) {
    buff = bufferTrack[i];
    st.push(i);
    st.push('[');
    st.push(buff.current + 1);
    st.push('/');
    st.push(buff.max);
    st.push('/');
    st.push(buff.max - buff.nextIndex.length);
    st.push(']');
  }

  info.push('Buffers ' + st.join(' '));
  hud.html('<pre>' + info.join('\n') + '</pre>');
}

setInterval(updateStats, 1000);

// ----------------------------------------------------------- //

// let sim = Sim();
let selectedHost = null;
let hosts = new Map();
let indexToHost = new Map();
let links = new Map();
let streams = new Map();
let maxLinkAge = 1000 * 60;
let maxHostAge = 1000 * 60;
let parStats = {
  fps: 0,
  frame: 0,
  lowFps: 0,
  simStats: {
    forceUpdateTime: 0,
    fps: 0,
    octreeBuildTime: 0,
    octreeNodes: 0,
    octreeSplitThreshold: 0,
    octreeTheta: 0,
    octreeMaxLevel: 0
  }
};

setNetworkOpts({
  hosts,
  links,
  parseFlow,
  paused: false,
  sim: options.sim,
  numGen: options.numGen
});

function updateNodesFromSim(simNodes) {
  let i, host, node, l = simNodes.length;
  if (l === undefined) {
    host = hosts.get(simNodes.id);
    if (!host || simNodes.pos.x === null) {
      return;
    }
    host.pos.copy(simNodes.pos);
    host.dirty = true;
    return;
  }
  for (i = 0; i < l; i++) {
    node = simNodes[i];
    host = hosts.get(node.id);
    if (!host) {
      continue;
    }
    host.pos.copy(node.pos);
    host.dirty = true;
  }
}

function mkHost(opts) {
  return Object.assign({
    lastSeen: Date.now(),
    vertexId: null,
    colorVertexId: null,
    pos: new Vector3(),
    dirty: true,
    links: new Map(),
    hostname: ''
  }, opts);
}

function parseFlow(flow) {
  // console.log(flow);
  if (flow.ipv4_src_addr === flow.ipv4_dst_addr) {
    return;
  }
  // ignore broadcasts
  let o = flow.ipv4_src_addr.split('.');
  if (o[3] === '255' || o[3] === '0' || o[0] === '255' || o[0] === '0') {
    return;
  }
  o = flow.ipv4_dst_addr.split('.');
  if (o[3] === '255' || o[3] === '0' || o[0] === '255' || o[0] === '0') {
    return;
  }
  let dstHost = mkHost({id: flow.ipv4_src_addr, ip: flow.ipv4_src_addr});
  let srcHost = mkHost({id: flow.ipv4_dst_addr, ip: flow.ipv4_dst_addr});
  if (!filterHost(srcHost)) {
    return;
  }
  if (!filterHost(dstHost)) {
    return;
  }

  srcHost = addHost(srcHost);
  if (!srcHost) {
    return;
  }
  dstHost = addHost(dstHost);
  if (!dstHost) {
    return;
  }
  addStream(flow, addLink(srcHost, dstHost));
}

function addStream(flow, link) {
  if (!link) {
    return null;
  }
  let sid = link.src.id + '-' + link.dst.id + '-' + flow.protocol, stream = streams.get(sid);
  if (!stream) {
    stream = {
      id: sid,
      link: link,
      protocol: flow.protocol,
      bytes: 0,
      packets: [],
      nextPacket: Date.now()
    };
    streams.set(stream.id, stream);
  }
  stream.bytes += flow.in_pkts;
  return stream;
}

function updateStreams(delta) {
  let packet, link, i, now = Date.now();
  streams.forEach((stream) => {
    link = stream.link;
    if (links.has(link.id)) {
      link.lastSeen = now;
    } else {
      stream.bytes = 0;
      stream.nextPacket = 0;
      stream.packets.forEach((p) => {
        rmGlPacket(p);
      });
      stream.packets.length = 0;
    }
    if (now >= stream.nextPacket) {
      if (stream.bytes <= 0 && !stream.packets.length) {
        streams.delete(stream.id);
        return;
      }
      stream.nextPacket = now + 100;
      if (stream.bytes <= 0) {
        return;
      }
      if (stream.bytes > 0) {
        stream.bytes--;
      }
      addPacket(stream);
    } // if now

    for (i = 0; i < stream.packets.length; i++) {
      packet = stream.packets[i];
      packet.t += delta;
      if (packet.t >= 1) {
        rmGlPacket(packet);
        stream.packets.splice(i, 1);
        i--;
        continue;
      }
      // new Vector3.lerp(packet.pos, link.src.pos, link.dst.pos, packet.t);
      packet.pos.lerpVectors(link.src.pos, link.dst.pos, packet.t);
    } // for i
  }); // streams.forEach
} // updateStreams

function addPacket(stream) {
  if (stream.packets.length > 100) {
    return null;
  }
  let packet = {
    created: Date.now(),
    stream: stream,
    vertexId: null,
    pos: stream.link.src.pos.clone(),
    t: 0
  };
  if (!addGlPacket(packet) || packet.vertexId === null) {
    return null;
  }
  stream.packets.push(packet);
  return packet;
}

function rmLink(link) {
  if (!links.delete(link.id)) {
    return link;
  }
  simRpc('rmLink', link.id);
  rmGlLink(link);

  return link;
}

function addLink(src, dst) {
  if (src.id === dst.id) {
    return null;
  }
  let linkId = mkLinkId(src.id, dst.id);
  let link = links.get(linkId);
  if (link) {
    link.lastSeen = Date.now();
    return link;
  }

  link = {id: linkId, src: src, dst: dst, vertexId: null, lastSeen: Date.now()};
  if (!addGlLink(link)) {
    return null;
  }
  links.set(linkId, link);
  simRpc('addLink', {id: linkId, src: link.src.id, dst: link.dst.id});
  // simRpc('addNode', {id: src.id, pos: src.pos}, false);
  // simRpc('addNode', {id: dst.id, pos: dst.pos}, false);
  src.links.set(dst.id, link);
  dst.links.set(src.id, link);
  if (selectedHost === src || selectedHost === dst) {
    selectHost(selectedHost);
  }

  return link;
}

function filterHost(host) {
  if (bufferTrack.host.current >= bufferTrack.host.max) {
    return false;
  }
  host.network = ipToNetwork(host.ip);
  if (options.localOnly) {
    return host.network.name !== 'WORLD';
  }
  return true;
}

function rmHost(host) {
  // console.log('Removing host',host);
  if (selectedHost === host) {
    selectHost(null);
    toolTip.hide();
  }

  simRpc('rmNode', host.id);
  rmGlHost(host);

  hosts.delete(host.id);
}

function addHost(host) {
  if (!hosts.has(host.id)) {
    if (!addGlHost(host)) {
      return null;
    }
    if (options.dns) {
      requestDns(host.ip);
    }
    hosts.set(host.id, host);
    // simRpc('addNode', {id: host.id, pos: host.pos}, false);
  } else {
    host = hosts.get(host.id);
    host.lastSeen = Date.now();
  }
  return host;
}

function timeoutObjects() {
  if (options.sim) {
    return;
  }
  const now = Date.now();
  hosts.forEach((host) => {
    host.links.forEach((link) => {
      if (now - link.lastSeen > maxLinkAge) {
        host.links.delete(link.dst.id);
        hosts.get(link.dst.id).links.delete(host.id);
        rmLink(link);
      }
    });
    if (!host.links.size && now - host.lastSeen > maxHostAge) {
      rmHost(host);
    }
  }); // forEach host
}

setInterval(function() {
  simRpc('sendStats');
}, 1000);
setInterval(function() {
  console.log(parStats.simStats);
}, 10 * 1000);

// ----------------------------------------------------------- //

if (!Detector.webgl) {
  Detector.addGetWebGLMessage();
}

let container, world, octreeParent, stats, simStats, clock, camera, controls, raycaster, scene, renderer,
  particles = {}, lines = {}, octree, worldBB = new Box3();
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

let bufferTrack = {
  host: {
    numAlloc: 1,
    max: 2000,
    current: 0,
    material: null,
    vertexBuff: null,
    colorBuff: null,
    idBuff: null,
    geometry: null,
    nextIndex: []
  },
  genericLine: {
    numAlloc: 2,
    max: 6000,
    current: 0,
    material: null,
    vertexBuff: null,
    colorBuff: null,
    geometry: null,
    nextIndex: []
  },
  flare: {
    numAlloc: 1,
    max: 5000,
    current: 0,
    material: null,
    vertexBuff: null,
    colorBuff: null,
    geometry: null,
    nextIndex: []
  }
};

init();
animate();

function updateGlPackets() {
  let buff = bufferTrack.flare;
  let geo = buff.geometry;
  let positions = geo.attributes.position.array;
  let p, i, l, v, packet;

  streams.forEach((stream) => {
    for (i = 0, l = stream.packets.length; i < l; i++) {
      packet = stream.packets[i];
      v = packet.vertexId;
      if (v === undefined || v === null) {
        continue;
      }
      p = packet.pos;
      positions[v] = p.x;
      positions[v + 1] = p.y;
      positions[v + 2] = p.z;
    }
  });
  geo.attributes.position.needsUpdate = true;
}

function updateGlHosts() {
  let buff = bufferTrack.host;
  let geo = buff.geometry;
  let positions = geo.attributes.position.array;
  let p, v, anyUpdated = false;

  worldBB.makeEmpty();
  hosts.forEach((h) => {
    worldBB.expandByPoint(h.pos);
    if (!h.dirty) {
      return;
    }
    v = h.vertexId;
    if (v === undefined || v === null) {
      return;
    }
    h.dirty = false;
    anyUpdated = true;
    p = h.pos;
    positions[v] = p.x;
    positions[v + 1] = p.y;
    positions[v + 2] = p.z;
  }); // forEach hosts
  geo.attributes.position.needsUpdate = anyUpdated;

  if (!anyUpdated) {
    return;
  }

  buff = bufferTrack.genericLine;
  geo = buff.geometry;
  positions = geo.attributes.position.array;

  links.forEach((link) => {
    v = link.vertexId;
    if (v === undefined || v === null) {
      return;
    }
    p = link.src.pos;
    positions[v] = p.x;
    positions[v + 1] = p.y;
    positions[v + 2] = p.z;

    p = link.dst.pos;
    positions[v + 3] = p.x;
    positions[v + 4] = p.y;
    positions[v + 5] = p.z;
  }); // forEach links
  geo.attributes.position.needsUpdate = true;
}

function rmGlHost(host) {
  if (host.vertexId === null) {
    return null;
  }

  let buff = bufferTrack.host;
  let geo = buff.geometry;
  buff.nextIndex.push(host.vertexId / 3);

  buff.vertexBuff[host.vertexId] = -100000;
  buff.vertexBuff[host.vertexId + 1] = -100000;
  buff.vertexBuff[host.vertexId + 2] = -100000;

  geo.attributes.position.needsUpdate = true;

  indexToHost.delete(Math.floor(host.vertexId / 3));
  host.vertexId = null;
  host.colorVertexId = null;
  return host;
}

function addGlHost(host) {
  let buff = bufferTrack.host;
  host.vertexId = buff.nextIndex.pop();
  if (host.vertexId === undefined) {
    return null;
  }
  let geo = buff.geometry;

  if (host.vertexId >= buff.current) {
    buff.current = host.vertexId;
    geo.setDrawRange(0, buff.current + 1);
  }

  let x = Math.random() * 2000 - 1000;
  let y = Math.random() * 2000 - 1000;
  let z = Math.random() * 2000 - 1000;

  indexToHost.set(host.vertexId, host);
  let cv = host.colorVertexId = host.vertexId * 4;
  host.vertexId *= 3;

  let positions = geo.attributes.position.array;
  positions [host.vertexId] = x;
  positions [host.vertexId + 1] = y;
  positions [host.vertexId + 2] = z;
  geo.attributes.position.needsUpdate = true;

  let colors = geo.attributes.mycolor.array;
  colors[cv] = host.network.color[0];
  colors[cv + 1] = host.network.color[1];
  colors[cv + 2] = host.network.color[2];
  colors[cv + 3] = selectedHost ? 0.1 : 1.0;
  geo.attributes.mycolor.needsUpdate = true;

  return host;
}

function rmGlLink(link) {
  if (link.vertexId === null) {
    return null;
  }
  let buff = bufferTrack.genericLine;
  let geo = buff.geometry;

  buff.nextIndex.push(link.vertexId / 6);

  buff.vertexBuff[link.vertexId] = -100000;
  buff.vertexBuff[link.vertexId + 1] = -100000;
  buff.vertexBuff[link.vertexId + 2] = -100000;

  buff.vertexBuff[link.vertexId + 3] = -100000;
  buff.vertexBuff[link.vertexId + 4] = -100000;
  buff.vertexBuff[link.vertexId + 5] = -100000;

  geo.attributes.position.needsUpdate = true;

  link.vertexId = null;
  return link;
}

function addGlLink(link) {
  let buff = bufferTrack.genericLine;
  link.vertexId = buff.nextIndex.pop();
  if (link.vertexId === undefined) {
    return null;
  }
  let geo = buff.geometry;
  geo.attributes.color.needsUpdate = true;
  let lineColors = geo.attributes.color.array;

  if (link.vertexId >= buff.current) {
    buff.current = link.vertexId;
    geo.setDrawRange(0, (buff.current + 1) * 2);
  }

  let hostPositions = bufferTrack.host.vertexBuff;

  let src = link.src.vertexId;
  let dst = link.dst.vertexId;

  let v = link.vertexId *= 6;

  buff.vertexBuff[v] = hostPositions[src];
  buff.vertexBuff[v + 1] = hostPositions[src + 1];
  buff.vertexBuff[v + 2] = hostPositions[src + 2];

  buff.vertexBuff[v + 3] = hostPositions[dst];
  buff.vertexBuff[v + 4] = hostPositions[dst + 1];
  buff.vertexBuff[v + 5] = hostPositions[dst + 2];

  let c = selectedHost ? 0.0 : 0.25;

  lineColors[v] = c;
  lineColors[v + 1] = c;
  lineColors[v + 2] = c;
  lineColors[v + 3] = c;
  lineColors[v + 4] = c;
  lineColors[v + 5] = c;

  geo.attributes.position.needsUpdate = true;
  return link;
}

function addGlPacket(packet) {
  let buff = bufferTrack.flare;
  packet.vertexId = buff.nextIndex.pop();
  if (packet.vertexId === undefined) {
    return null;
  }
  let geo = buff.geometry;

  if (packet.vertexId >= buff.current) {
    buff.current = packet.vertexId;
    geo.setDrawRange(0, buff.current + 1);
  }

  let cv = packet.vertexId * 4;
  packet.vertexId *= 3;

  let positions = geo.attributes.position.array;
  positions[packet.vertexId] = packet.pos.x;
  positions[packet.vertexId + 1] = packet.pos.y;
  positions[packet.vertexId + 2] = packet.pos.z;
  geo.attributes.position.needsUpdate = true;

  let color = protoColors[packet.stream.protocol];
  if (!color) {
    color = protoColors.other;
  }

  let colors = geo.attributes.mycolor.array;
  colors[cv] = color.color[0];
  colors[cv + 1] = color.color[1];
  colors[cv + 2] = color.color[2];
  colors[cv + 3] = 0.5;
  geo.attributes.mycolor.needsUpdate = true;

  return packet;
}

function rmGlPacket(packet) {
  if (packet.vertexId === null) {
    return null;
  }

  let buff = bufferTrack.flare;
  let geo = buff.geometry;
  buff.nextIndex.push(Math.floor(packet.vertexId / 3));

  let position = geo.attributes.position.array;
  position[packet.vertexId] = -100000;
  position[packet.vertexId + 1] = -100000;
  position[packet.vertexId + 2] = -100000;
  geo.attributes.position.needsUpdate = true;

  packet.vertexId = null;
  return packet;
}

function selectHost(host) {
  let buff, geo, lineColors, hostColors, v, c = host ? 0 : 0.25;

  buff = bufferTrack.genericLine;
  geo = buff.geometry;
  geo.attributes.color.needsUpdate = true;
  lineColors = geo.attributes.color.array;

  links.forEach((link) => {
    v = link.vertexId;
    if (v === null) {
      return;
    }
    lineColors[v] = c;
    lineColors[v + 1] = c;
    lineColors[v + 2] = c;
    lineColors[v + 3] = c;
    lineColors[v + 4] = c;
    lineColors[v + 5] = c;
  });

  buff = bufferTrack.host;
  geo = buff.geometry;
  geo.attributes.mycolor.needsUpdate = true;
  hostColors = geo.attributes.mycolor.array;

  hosts.forEach((h) => {
    hostColors[h.colorVertexId + 3] = host ? 0.1 : 1;
  });

  selectedHost = host;
  if (!host) {
    return;
  }

  host.links.forEach((link) => {
    v = link.vertexId;
    if (v === null) {
      return;
    }
    lineColors[v] = 0.5;
    lineColors[v + 1] = 0.5;
    lineColors[v + 2] = 0.5;
    lineColors[v + 3] = 0.5;
    lineColors[v + 4] = 0.5;
    lineColors[v + 5] = 0.5;

    hostColors[link.src.colorVertexId + 3] = 1;
    hostColors[link.dst.colorVertexId + 3] = 1;
  });

  toolTip.html(
    '<div>' + host.ip + '</div>' +
    '<div>' + host.hostname + '</div>' +
    '<div>' + host.network.name + '</div>'
  );
}

function buildOctree() {
  if (!options.showOctree) {
    octreeParent.setVisible(false);
    return;
  }
  octreeParent.setVisible(true);
  let s = Math.abs(Math.min(Math.min(worldBB.min.x, worldBB.min.y), worldBB.min.z));
  s = Math.max(Math.abs(Math.max(Math.max(worldBB.max.x, worldBB.max.y), worldBB.max.z)), s) + 20;
  worldBB.min = new Vector3(-s, -s, -s);
  worldBB.max = new Vector3(s, s, s);

  timer.start('octreeBuildTime');
  octree = new Octree(worldBB, {
    maxLevel: options.octreeMaxLevel,
    splitThreshold: options.octreeSplitThreshold,
    vizHelper: octreeParent
  });

  hosts.forEach((host) => {
    octree.add(host);
  });
  octree.updateViz();

  parStats.octreeBuildTime = timer.duration('octreeBuildTime', 2);
}

function init() {
  container = document.getElementById('canvas');

  clock = new Clock();

  camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 2000);
  camera.position.z = 500;
  camera.lookAt(new Vector3(0, 0, 0));

  controls = new TrackballControls(camera);
  controls.rotateSpeed = 10.0;
  controls.zoomSpeed = 1.25;
  controls.panSpeed = 1.0;
  controls.noZoom = false;
  controls.noPan = false;
  controls.staticMoving = true;
  controls.dynamicDampingFactor = 0.3;
  controls.keys = [65, 83, 68];
  // controls.keys = [];
  // controls.addEventListener( 'change', render );

  raycaster = new Raycaster();
  raycaster.params.Points.threshold = 10;

  scene = new Scene();
  scene.name = 'scene';

  window.scene = scene;

  world = new Object3D();
  world.name = 'world';
  scene.add(world);

  octreeParent = new OctreeVisHelper(0xffff00, 3000);
  octreeParent.name = 'octree';
  world.add(octreeParent.getSceneObject());

  // scene.fog = new THREE.FogExp2(0x000000, 0.0005);
  scene.fog = new Fog(0x000000, 500, 2000);
  scene.fog.name = 'fog';

  let textureLoader = new TextureLoader();
  let hostTexture, flareTexture;

  hostTexture = textureLoader.load('img/textures/host.png');
  flareTexture = textureLoader.load('img/textures/flare.png');
  // networkTexture = textureLoader.load("img/textures/network.png");
  // serviceTexture = textureLoader.load("img/textures/service.png");

  let parameters = [
    {id: 'genericLine', color: 0x555555, size: 2, fog: true}
  ];
  let i, j, id, mat, geo, line;
  for (i = 0; i < parameters.length; i++) {
    id = parameters[i].id;
    bufferTrack[id].vertexBuff = new Float32Array(bufferTrack[id].max * bufferTrack[id].numAlloc * 3);
    bufferTrack[id].colorBuff = new Float32Array(bufferTrack[id].max * bufferTrack[id].numAlloc * 3);
    for (j = bufferTrack[id].max - 1; j >= 0; j--) {
      bufferTrack[id].nextIndex.push(j);
      bufferTrack[id].colorBuff[j * 3] = 0.25;
      bufferTrack[id].colorBuff[j * 3 + 1] = 0.25;
      bufferTrack[id].colorBuff[j * 3 + 2] = 0.25;
    }
    mat = new LineBasicMaterial({
      // color: 0x555555,
      vertexColors: VertexColors,
      linewidth: parameters[i].size,
      fog: parameters[i].fog
    });
    bufferTrack[id].material = mat;

    geo = new BufferGeometry();
    geo.setAttribute('position', new BufferAttribute(bufferTrack[id].vertexBuff, 3));
    geo.setAttribute('color', new BufferAttribute(bufferTrack[id].colorBuff, 3));
    geo.attributes.position.setUsage(DynamicDrawUsage);
    geo.attributes.color.setUsage(DynamicDrawUsage);
    geo.setDrawRange(0, 0);
    bufferTrack[id].geometry = geo;

    line = new LineSegments(geo, mat);
    line.frustumCulled = false;
    line.name = id;

    lines[id] = line;
    world.add(line);
  }

  parameters = [
    {id: 'host', color: [0, 1, 0], texture: hostTexture, size: 50.0, idbuff: false, fog: true},
    {id: 'flare', color: [1, 1, 1], texture: flareTexture, size: 25.0, idbuff: false, fog: true}
  ];

  for (i = 0; i < parameters.length; i++) {
    id = parameters[i].id;
    bufferTrack[id].vertexBuff = new Float32Array(bufferTrack[id].max * bufferTrack[id].numAlloc * 3);
    bufferTrack[id].colorBuff = new Float32Array(bufferTrack[id].max * bufferTrack[id].numAlloc * 4);
    if (parameters[i].idBuff) {
      bufferTrack[id].idBuff = new Int32Array(bufferTrack[id].max * bufferTrack[id].numAlloc);
    }

    for (j = bufferTrack[id].max - 1; j >= 0; j--) {
      bufferTrack[id].nextIndex.push(j);
      bufferTrack[id].colorBuff[j * 3] = parameters[i].color[0];
      bufferTrack[id].colorBuff[j * 3 + 1] = parameters[i].color[1];
      bufferTrack[id].colorBuff[j * 3 + 2] = parameters[i].color[2];
      bufferTrack[id].colorBuff[j * 3 + 2] = 1;
      if (bufferTrack[id].idBuff) {
        bufferTrack[id].idBuff[j] = j;
      }
    }

    mat = new ShaderMaterial({
      uniforms: {
        size: {value: parameters[i].size},
        map: {value: parameters[i].texture},
        opacity: {value: 1.0},
        selectedId: {value: -1},
        fogColor: {type: 'c', value: scene.fog.color},
        fogNear: {type: 'f', value: scene.fog.near},
        fogFar: {type: 'f', value: scene.fog.far}
      },

      vertexShader: document.getElementById('vertexShaderHost').textContent,
      fragmentShader: document.getElementById('fragmentShaderHost').textContent,
      vertexColors: VertexColors,
      blending: NormalBlending,
      depthTest: true,
      depthWrite: true,
      transparent: true,
      fog: true,
      lights: false,
      alphaTest: 0.05,
      opacity: 1.0

    });
    mat.map=parameters[i].texture;

    // mat = new THREE.PointsMaterial({
    //   size: parameters[i].size,
    //   map: parameters[i].texture,
    //   // color: parameters[i].color,
    //   vertexColors: THREE.VertexColors,
    //   blending: THREE.NormalBlending,
    //   depthTest: true,
    //   depthWrite: true,
    //   transparent: true,
    //   fog: parameters[i].fog,
    //   lights: false,
    //   alphaTest: 0.05,
    //   opacity: 1.0,
    //   precision: null
    // });
    bufferTrack[id].material = mat;

    geo = new BufferGeometry();
    geo.setAttribute('position', new BufferAttribute(bufferTrack[id].vertexBuff, 3));
    geo.setAttribute('mycolor', new BufferAttribute(bufferTrack[id].colorBuff, 4));
    geo.attributes.position.setUsage(DynamicDrawUsage);
    geo.attributes.mycolor.setUsage(DynamicDrawUsage);
    geo.setDrawRange(0, 0);
    bufferTrack[id].geometry = geo;

    // geo.setAttribute('color', new THREE.BufferAttribute(colors[id], 3));
    // geo.setAttribute('size', new THREE.BufferAttribute(sizes[id], 1));
    // geo.setAttribute('opacity', new THREE.BufferAttribute(opacities[id], 1));

    let part = new Points(geo, mat);
    part.name = id;

    // part.rotation.x = Math.random() * 6;
    // part.rotation.y = Math.random() * 6;
    // part.rotation.z = Math.random() * 6;
    particles[id] = part;

    if (id !== 'flare1') {
      world.add(part);
    }

  }

  renderer = new WebGLRenderer();
  renderer.setPixelRatio(window.devicePixelRatio || 1);
  renderer.setSize(window.innerWidth, window.innerHeight);
  // renderer.sortObjects = true;
  container.appendChild(renderer.domElement);

  stats = new Stats();
  stats.dom = stats.dom || false; // shut up inspector
  stats.dom.style.cssText = 'position:fixed;left:0;bottom:0;cursor:pointer;opacity:0.9;z-index:10000';
  container.appendChild(stats.dom);

  simStats = new Stats();
  simStats.dom = simStats.dom || false; // shut up inspector
  simStats.dom.style.cssText = 'position:fixed;right:0;bottom:0;cursor:pointer;opacity:0.9;z-index:10000';
  container.appendChild(simStats.dom);

  window.addEventListener('resize', onWindowResize, false);
}

function onWindowResize() {
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
  controls.handleResize();
} // onWindowResize

// let windowFocused = true;
// $(window).on('blur focus', function(e) {
//   let prevType = $(this).data('prevType');
//
//   if (prevType !== e.type) {   //  reduce double fire issues
//     switch (e.type) {
//       case 'blur':
//         windowFocused = false;
//         simRpc('setPaused',true);
//         break;
//       case 'focus':
//         windowFocused = true;
//         simRpc('setPaused',false);
//         break;
//     }
//   }
//
//   $(this).data('prevType', e.type);
// });

$(document).click(function(e) {
  if (options.dialogOpen) {
    return;
  }
  // console.log(e);
  if (!e.ctrlKey) {
    return;
  }
  e.preventDefault();
  mouse.x = (e.clientX / renderer.domElement.width) * 2 - 1;
  mouse.y = -(e.clientY / renderer.domElement.height) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);
  bufferTrack.host.geometry.computeBoundingSphere();
  let intersects = raycaster.intersectObject(particles.host, false);
  // console.log(mouse, intersects);
  if (intersects && intersects.length) {
    //console.log( 'intersects', intersects );
    // Points.js::raycast() doesn't seem to sort this correctly atm,
    // but how many points are found depends on the threshold set
    // on the raycaster as well
    // intersects = intersects.sort( function( a, b ) {
    //   return a.distanceToRay - b.distanceToRay;
    // });
    intersects = intersects.filter((a) => {
      return a.point.z > -999999 && indexToHost.has(a.index);
    });
  }
  if (intersects && intersects.length) {
    let particle = intersects[0];
    selectHost(indexToHost.get(particle.index));
  } else {
    toolTip.hide();
    selectedHost = null;
    selectHost(selectedHost);
  }
});

let animateCalled = true;

function animate() {
  requestAnimationFrame(animate);
  animateCalled = true;
  parStats.frame++;

  let delta = clock.getDelta();
  if (!delta) {
    delta = 0.01;
  } else if (delta > 0.2) {
    delta = 0.2;
  }

  updateStreams(delta);
  updateGlHosts();
  updateGlPackets();

  if (selectedHost && keysDown.shift) {
    // console.log(selectedHost);
    // debugger;
    let vector = new Vector3(selectedHost.pos.x, selectedHost.pos.y, selectedHost.pos.z);
    vector.applyMatrix4(world.matrixWorld);
    vector.project(camera);
    vector.x = (vector.x * windowHalfX) + windowHalfX;
    vector.y = -(vector.y * windowHalfY) + windowHalfY;
    toolTip.css({left: vector.x, top: vector.y}).show();
  } else if (selectedHost) {
    toolTip.hide();
  }
  controls.update(delta);

  if (options.autoRotate && !keysDown.ctrl) {
    world.rotateY(delta * 0.1);
  }

  buildOctree();

  if (hosts.size) {
    render(delta);
  }

  stats.update();
}

function render() {
  renderer.render(scene, camera);
}

function pause(p) {
  options.paused = p;
  simRpc('setPaused', options.paused);
  setNetworkOpts({paused: options.paused});
  console.log('paused', options.paused);
}

function frameRateLogic() {
  parStats.fps = parStats.frame;
  parStats.frame = 0;
  if (!options.paused) {
    if (parStats.fps < 30) {
      parStats.lowFps++;
      if (parStats.lowFps === 5) {
        simRpc('setPaused', true);
        console.log('low FPS detected, pausing simulation worker');
      }
    } else {
      if (parStats.lowFps >= 5) {
        setTimeout(() => {
          simRpc('setPaused', options.paused);
          console.log('low FPS resolved, resuming simulation worker');
        }, 3000);
      }
      parStats.lowFps = 0;
    }
  }
  if (!animateCalled && !options.paused) {
    pause(true);
    return;
  }
  if (animateCalled && options.paused) {
    pause(false);
    return;
  }
  animateCalled = false;
}

setInterval(frameRateLogic, 1000);
setInterval(timeoutObjects, 5000);

setTimeout(() => {
  $('#help').hide();
}, 30000);


