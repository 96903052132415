// @flow
import {
  Box3,
  BufferGeometry,
  BufferAttribute,
  LineBasicMaterial,
  LineSegments
} from '../node_modules/three/build/three.module';

class OctreeVizHelper {
  updating: boolean;
  boxes: Array<Box3>;
  maxBoxes: number;
  geometry: Object;
  material: Object;
  obj: LineSegments;

  constructor(color: number | string = 0xffff00, maxBoxes: number = 1000) {
    let indexTemplate = new Uint16Array([0, 1, 1, 2, 2, 3, 3, 0, 4, 5, 5, 6, 6, 7, 7, 4, 0, 4, 1, 5, 2, 6, 3, 7]);
    let indices = new Uint16Array(24 * maxBoxes);
    let positions = new Float32Array(8 * 3 * maxBoxes);
    let i, j;
    for (i = 0; i < maxBoxes; i++) {
      for (j = 0; j < 24; j++) {
        indices[i * 24 + j] = indexTemplate[j] + i * 8;
      }
    }

    let geometry = this.geometry = new BufferGeometry();
    geometry.setIndex(new BufferAttribute(indices, 1));
    geometry.setAttribute('position', new BufferAttribute(positions, 3));
    geometry.setDrawRange(0, 0);

    this.material = new LineBasicMaterial({color: color});

    this.obj = new LineSegments(geometry, this.material);
    this.obj.matrixAutoUpdate = false;

    this.updating = false;
    this.maxBoxes = maxBoxes;
    this.boxes = [];
  }
  setVisible(visible: boolean){
    this.obj.visible = visible;
  }
  getSceneObject(): LineSegments {
    return this.obj;
  }

  clearBoxes() {
    this.boxes.length = 0;
    this.updating = false;
    this.update();
  }

  beginUpdate() {
    this.updating = true;
  }

  endUpdate() {
    this.updating = false;
    this.update();
  }

  addBox(box: Box3): boolean | number {
    let l = this.boxes.length;
    if (l >= this.maxBoxes) {
      return false;
    }
    this.boxes.push(box);
    this.update();
    return l;
  }

  update() {
    if (this.updating) {
      return;
    }

    let boxes = this.boxes, l = boxes.length, geo = this.geometry;
    if (!l) {
      geo.setDrawRange(0, 0);
      return;
    }
    let i, o, min, max, box;
    let position = geo.attributes.position, array = position.array;

    for (i = 0; i < l; i++) {
      o = i * 24;
      box = boxes[i];
      min = box.min;
      max = box.max;
      /*
        5____4
      1/___0/|
      | 6__|_7
      2/___3/
      0: max.x, max.y, max.z
      1: min.x, max.y, max.z
      2: min.x, min.y, max.z
      3: max.x, min.y, max.z
      4: max.x, max.y, min.z
      5: min.x, max.y, min.z
      6: min.x, min.y, min.z
      7: max.x, min.y, min.z
      */

      array[0 + o] = max.x; // 0
      array[1 + o] = max.y;
      array[2 + o] = max.z;
      array[3 + o] = min.x; // 1
      array[4 + o] = max.y;
      array[5 + o] = max.z;
      array[6 + o] = min.x; // 2
      array[7 + o] = min.y;
      array[8 + o] = max.z;
      array[9 + o] = max.x; // 3
      array[10 + o] = min.y;
      array[11 + o] = max.z;
      array[12 + o] = max.x; // 4
      array[13 + o] = max.y;
      array[14 + o] = min.z;
      array[15 + o] = min.x; // 5
      array[16 + o] = max.y;
      array[17 + o] = min.z;
      array[18 + o] = min.x; // 6
      array[19 + o] = min.y;
      array[20 + o] = min.z;
      array[21 + o] = max.x; // 7
      array[22 + o] = min.y;
      array[23 + o] = min.z;
    }

    position.needsUpdate = true;
    geo.setDrawRange(0, l * 24);
  } //update
}

export default OctreeVizHelper;
