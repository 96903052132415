// @flow
'use strict';

import OctreeVizHelper from './OctreeVizHelper';
import { Vector3, Box3 } from '../node_modules/three/build/three.module';

const center = new Vector3();

type NodeType = { id: string, pos: Vector3, mass: number };

type CalcForcesOptionsType = {
  cbf: (node: NodeType, NodeType | { mass: number, pos: Vector3 }) => boolean,
  theta: number,
  stats: Object
};

type OctreeOptionsType = {
  maxLevel: number,
  splitThreshold: number,
  debug: boolean,
  log: (msg: string) => void,
  vizHelper: OctreeVizHelper | null
};

class Octree {
  nextId: number;
  bb: Box3;
  size: Vector3;
  root: OctreeCell;
  maxDim: number;
  maxLevel: number;
  splitThreshold: number;
  stats: { cells: number, nodes: number };
  vizHelper: OctreeVizHelper | null;
  log: (msg: string) => void;
  debug: boolean;

  constructor(bb: Box3, opts: OctreeOptionsType) {
    this.nextId = 0; // id's assigned to cells
    this.bb = bb; // bounding box
    this.size = bb.getSize(center);
    this.maxLevel = 3; // max recursion level
    this.splitThreshold = 1; // how many items is a node allowed to have for it splits
    this.vizHelper = null; // visual helper to display octree
    this.stats = {cells: 0, nodes: 0}; // some stats
    this.debug = false; // should debug output be logged
    this.log = (msg: string) => {}; // dummy logger, one can be passed in if needed for debugging

    Object.assign(this, opts); // override options on self with passed in opts

    let size = this.size;
    this.maxDim = Math.max(size.x, Math.max(size.y, size.z)); // max dimension of bounding box

    this.root = new OctreeCell(this, null, bb); // create root node
  }

  // generates ids for cells
  getNextId(): number {
    return this.nextId++;
  }
  // update visual helper;
  updateViz(){
    if (!this.vizHelper){
      return;
    }
    if (this.vizHelper) {
      this.vizHelper.clearBoxes();
    }
    this.vizHelper.beginUpdate();
    this.root.updateViz();
    if (this.vizHelper) {
      this.vizHelper.endUpdate();
    }
  }
  // compute center and total mass for all cells
  computeMass() {
    this.root.computeMass();
  }

  // add a point and its data to tree
  add(node: NodeType) {
    this.stats.nodes++;
    this.root.add(node);
  }

  // used to compute node gravity interactions
  calcForces(node: NodeType, options: CalcForcesOptionsType) {
    if (this.root.mass === undefined) { // if we have not computed our cell mass do so on first call calcForces
      this.computeMass();
    }
    this.root.calcForces(node, options);
  }
}

class OctreeCell {
  id: number;
  tree: Octree;
  parent: OctreeCell | null;
  bb: Box3;
  size: Vector3;
  maxDim: number;
  center: Vector3;
  nodes: Array<NodeType>;
  level: number ;
  children: Array<OctreeCell> ;
  hasDisplay: boolean | number;
  massNum: number;
  mass: number;
  massCenter: Vector3;

  constructor(tree: Octree, parent: OctreeCell | null, bb: Box3) {
    tree.stats.cells++;
    this.id = tree.getNextId();
    this.tree = tree;
    this.parent = parent;
    this.bb = bb;
    this.size = bb.getSize(center);
    let size = this.size;
    this.maxDim = Math.max(size.x, Math.max(size.y, size.z));
    this.center = bb.getCenter(center);
    this.level = parent ? parent.level + 1 : 0;
    this.nodes = [];
    this.children = [];
    this.hasDisplay = false;
  }

  // used to get location of node in the tree
  getPathId(): string {
    if (!this.parent) {
      return '';
    }
    return this.parent.getPathId() + '->' + (this.parent ? this.parent.level : '') + ':' + (this.parent ? this.parent.children.indexOf(this) : '');
  }
  updateViz(){
    this.children.forEach((child: OctreeCell)=>{
      child.updateViz();
    });
    if (this.tree.vizHelper && this.hasDisplay === false && this.nodes.length) {
      this.hasDisplay = this.tree.vizHelper.addBox(this.bb);
    }
  }
  // calculate forces that should be applied to options.node
  calcForces(srcNode: NodeType, options: CalcForcesOptionsType) {
    let p = srcNode.pos;
    if (this.children.length) { // if we have children we do not contain nodes and need to check cells and their children
      let s, d;
      this.children.forEach((child: OctreeCell) => {
        if (child.mass) { // if child has no mass then its empty skip it entirely
          s = child.maxDim; // cells largest dimension
          d = p.distanceTo(child.massCenter) || 0.0001; // prevent div zero of point is at cell center
          if (s / d < options.theta) { // if ratio is less than options.theta we can use the cell as node instead of its children
            options.cbf(srcNode, {mass: child.mass, pos: child.massCenter}); // call force compute callback
            options.stats.totalChecks++;
            options.stats.groupChecks++;
          } else { // cell does not meet theta requirement recurse to children
            child.calcForces(srcNode, options);
            options.stats.groupSkipedTheta++;
          }
        } else { // no child.mass
          options.stats.groupSkipedEmpty++;
        }
      }); // children.forEach
      return;
    }
    // leaf node check its actual data points
    this.nodes.forEach((node: NodeType) => {
      if (srcNode === node) { // don't check self
        options.stats.totalSkips++;
        options.stats.selfSkips++;
        return;
      }
      if (options.cbf(srcNode, node) !== false) {
        options.stats.totalChecks++;
        options.stats.nodeChecks++;
      } else { // cbf says no calc was needed
        options.stats.totalSkips++;
        options.stats.cacheSkips++;
      }
    });
  }

  //recursively compute total and center of mass for each cell
  computeMass() {
    // init mass calc to zero
    this.massNum = 0;
    this.mass = 0;
    this.massCenter = new Vector3();
    // if we are a leaf add all nodes mass info to our total
    this.nodes.forEach((node: NodeType) => {
      this.massNum++;
      this.mass += node.mass || 1;
      this.massCenter.add(node.pos);
    });
    // have children compute their total mass which will also update ours
    this.children.forEach((child: OctreeCell) => {
      child.computeMass();
    });
    if (this.parent && this.massNum) {
      this.parent.massNum += this.massNum;
      this.parent.mass += this.mass;
      this.parent.massCenter.add(this.massCenter);
    }
    if (this.massNum > 1) {
      this.massCenter.multiplyScalar(1 / this.massNum);
    }
    if (this.tree.debug) {
      this.tree.log(this.getPathId() + ' mass:' + this.mass + ' center:' + new Vector3.toString(this.massCenter));
    }
  }

  add(node: NodeType) {
    if (this.children.length) {
      this.addToChildren(node);
    } else {
      if (this.level < this.tree.maxLevel && this.nodes.length >= this.tree.splitThreshold) {
        this.split();
        this.addToChildren(node);
      } else {
        this.nodes.push(node);
      }
    }

  }

  addToChildren(node: NodeType) {
    for (let i = 0, l = this.children.length; i < l; i++) {
      if (this.children[i].contains(node.pos)) {
        this.children[i].add(node);
        return;
      }
    }
  }

  contains(p: Vector3): boolean {
    return this.bb.containsPoint(p);
  }

  split() {
    let bb = this.bb, min = bb.min, max = bb.max, center = this.center;
    this.children.push(new OctreeCell(this.tree, this, new Box3(min, center)));
    this.children.push(new OctreeCell(this.tree, this, new Box3(new Vector3(center.x, min.y, min.z), new Vector3(max.x, center.y, center.z))));
    this.children.push(new OctreeCell(this.tree, this, new Box3(new Vector3(center.x, min.y, center.z), new Vector3(max.x, center.y, max.z))));
    this.children.push(new OctreeCell(this.tree, this, new Box3(new Vector3(min.x, min.y, center.z), new Vector3(center.x, center.y, max.z))));
    this.children.push(new OctreeCell(this.tree, this, new Box3(new Vector3(min.x, center.y, min.z), new Vector3(center.x, max.y, center.z))));
    this.children.push(new OctreeCell(this.tree, this, new Box3(new Vector3(center.x, center.y, min.z), new Vector3(max.x, max.y, center.z))));
    this.children.push(new OctreeCell(this.tree, this, new Box3(center, max)));
    this.children.push(new OctreeCell(this.tree, this, new Box3(new Vector3(min.x, center.y, center.z), new Vector3(center.x, max.y, max.z))));
    this.nodes.forEach((node: NodeType) => {
      this.addToChildren(node);
    });
    this.nodes.length = 0;
  }
}

export default Octree;
